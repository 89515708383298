<nav class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
    <div class="container px-5">
        <a class="navbar-brand fw-bold" routerLink="/" ><img [src]="logoImage" alt="..." /></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="bi-list"></i>
        </button>
        <div *ngIf="navigation" class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
                                        <li class="nav-item"><a class="nav-link me-lg-3" routerLink="/">Inicio</a></li>
                                        <li *ngIf="!auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/login">Login</a></li>
                                        <li *ngIf="auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/suscriptions">Suscripciones</a></li>
                                        <li *ngIf="auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/addresses">Direcciones</a></li>
                                        <li *ngIf="auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/payments">Tarjetas</a></li>
                                        <li *ngIf="auth.isLoggedIn()" class="nav-item"><a class="nav-link" routerLink="/change-password">Perfil</a></li>
                                        <li *ngIf="auth.isLoggedIn()" class="nav-item"><a style="cursor: pointer;" (click)="logout()" class="nav-link" >Salir</a></li>
            </ul>
        </div>
    </div>
</nav>
