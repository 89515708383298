<!-- Navigation-->
<app-menu [navigation]="aloudNavigation"></app-menu>

<div id="page-top" [class]="class">

    <!-- Mashead header-->
    <header  class="masthead coverim" id="inicio">
        <div class="container px-5">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <!-- Mashead text and app badges-->
                    <div class="mb-5 mb-lg-0 text-center text-lg-start">
                        <h1 *ngIf="isSuscriptionActive"  class="display-1 lh-1 mb-3">{{ title }}</h1>
                        <h1 *ngIf="!isSuscriptionActive"  class="display-1 lh-1 mb-3">PORTAL EN MANTENIMIENTO</h1>
                        <p class="lead fw-normal text-muted mb-5">{{ description }}</p>
                                                 <a *ngIf="aloudNavigation"  class="btn btn-primary main " href="#planes">Ver planes</a>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="">
                        <img style="width: 100%" [src]="getImagePrincipal()">
                    </div>
                </div>
            </div>
        </div>
    </header>

    <!-- Quote/testimonial aside-->
    <section *ngIf="isSuscriptionActive" class="page-section prices" id="planes">
        <div class="container">
            <div class="text-center">
                                <h2 class="section-heading">{{ price_title }}</h2>
                                <h3 class="section-subheading text-muted">Precios</h3>

            </div>
            <!-- MOSTRAMOS LOS PLANES QUE TIENE ASOCIADO EL SERVICIO DEL ONBOARDING CONFIG -->
            <app-plan [navigation]="aloudNavigation"  ></app-plan>
        </div>
    </section>

    <!-- Basic features section-->
    <section *ngIf="isSuscriptionActive"  class="bg-light" id="banner">
        <div class="container px-5">
            <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                                <div class="col-sm-8 col-md-6">
                    <div class="px-5 px-sm-0"><img class="img-fluid rounded-circle" [src]="getImagePromotional()" alt="..." /></div>
                </div>
                <div class="col-12 col-lg-5">
                    <h2 class="display-4 lh-1 mb-4">{{ banner_title }}</h2>
                    <p class="lead fw-normal text-muted mb-5 mb-lg-0">{{ banner_description }}</p>
                </div>

            </div>
        </div>
    </section>

</div>

<app-footer></app-footer>
