<div class="row no-padding-cols">
  <div *ngFor=" let plan of plans " class="col-lg-4 col-sm-6 mb-4">
    <div class="portfolio-item box-plan">
      <div class="img-port">
        <img [src]="plan.getImage()">
      </div>
      <div class="portfolio-caption">
        <div class="portfolio-caption-heading">{{ plan.getName() }} - ${{plan.getPrice()}}</div>
        <div class="portfolio-caption-subheading text-muted">{{ plan.getDescription() }}</div>
        <a  *ngIf="navigation" class="btn btn-primary " (click)="onSelectPlan(plan)" routerLink = "/checkout" >Seleccionar</a>
      </div>

    </div>
  </div>
</div>

<!--<div class="row no-padding-cols">-->
<!--  &lt;!&ndash; featured-plan &ndash;&gt;-->
<!--  <div *ngFor="let plan of plans" class="col-lg-4 col-sm-6 mb-4">-->
<!--    <div  class="portfolio-item box-plan">-->
<!--      <a class="portfolio-link">-->
<!--        <div class="portfolio-hover">-->
<!--          <div class="portfolio-hover-content"></div>-->
<!--        </div>-->
<!--        <img class="img-fluid" [src]="plan.getImage()" alt="..." />-->
<!--      </a>-->
<!--      <div class="portfolio-caption">-->
<!--        <div style="text-align: center;">-->
<!--          <p class="subdescrition">{{ plan.getName() }}</p>-->
<!--          <div  class="portfolio-caption-heading">${{plan.getPrice()}}</div>-->
<!--          <div class="portfolio-caption-subheading text-muted">{{ plan.getDescription() }}</div>-->
<!--          &lt;!&ndash;   <ul class="benefits">-->
<!--                <li>Beneficio 1</li>-->
<!--                <li>Beneficio 2</li>-->
<!--                <li>Beneficio 3</li>-->
<!--                -->
<!--            </ul> &ndash;&gt;-->
<!--          <a class="btn btn-primary " (click)="onSelectPlan(plan)" routerLink = "/checkout">Lo quiero</a>-->
<!--        </div>-->

<!--      </div>-->

<!--    </div>-->
<!--  </div>-->

<!--</div>-->
