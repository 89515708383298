import { Component, OnInit, Input } from '@angular/core';
import {Router} from "@angular/router";
import {PlanService} from "../../services/plan/plan.service";
import {Plan} from "./model/plan.model";

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css']
})
export class PlanComponent implements OnInit {

  plans : Plan[] = [];
  @Input() navigation = true;

  constructor(private router : Router, private planService : PlanService) { }

  ngOnInit(): void {
    this.planService.isSuscriptionActive.subscribe(( active : boolean) =>{
    this.planService.obtainPlans.subscribe( (plans : Plan[])=>{
      this.plans = plans;
      this.planService.setIsActive(active);
    })
  })
  }

  onSelectPlan(plan : Plan){
    this.planService.setPlanSelected(plan.getID());
  }



}
