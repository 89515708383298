<!-- Navigation-->
<app-menu></app-menu>

<div id="page-top" class="pal3 preferences">

  <!-- Masthead-->
  <header class="masthead" id="about">
    <div class="container">
      <p class="sites-description">Preferencias</p>
    </div>
  </header>
  <main class="d-flex flex-nowrap ">

    <app-side-menu></app-side-menu>

    <div class="b-example-divider b-example-vr"></div>
    <div class="container p-account">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <h4 class="m-0">Datos personales</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Nombre</label>
                <input type="name" class="form-control">
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Correo</label>
                <input type="email" class="form-control" placeholder="name@example.com">
              </div>
            </div>
            <div class="col-12">
              <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              <div class="invalid-feedback">
                Validación
              </div>
            </div>
            <div class="row actions">
              <div class="col-md-5 col-lg-4"></div>
              <div class="col-md-5 col-lg-4"></div>
              <div class="col-md-5 col-lg-4"><button class="w-100 btn btn-primary btn-lg" type="submit">Guardar</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

</div>
