<div id="page-top" [class]="class">


  <!-- Masthead-->
  <header  class="masthead coverim" id="inicio" >

    <div class="container">
      <main class="form-signin">
        <h1 style="text-align: center" class="display-1 lh-1 mb-3">Crea tu cuenta</h1>
        <p class="sites-description">Te enviaremos un correo con tus credenciales para poder acceder </p>
        <div class="col-md-12">

          <form [formGroup]="form" (ngSubmit)="signup()" >
            <div class="row g-3">

              <div class="col-sm-6">
                <label for="firstName" class="form-label">Nombre</label>
                <input [formControl]="form.controls['name_customer']"  type="text" class="form-control" id="firstName" placeholder="" value="" required="">
                <small
                  *ngIf="form.controls['name_customer'].hasError('required') && form.controls['name_customer'].touched"
                  class="form-error-msg"> Nombre es requerido </small>
              </div>

              <div class="col-sm-6">
                <label for="lastName" class="form-label">Apellido</label>
                <input [formControl]="form.controls['lastName_customer']" type="text" class="form-control" id="lastName" placeholder="" value="" required="">
                <small
                  *ngIf="form.controls['lastName_customer'].hasError('required') && form.controls['lastName_customer'].touched"
                  class="form-error-msg"> Apellido es requerido </small>
              </div>

              <div class="col-sm-4">
                <label for="cedula" class="form-label">Cédula</label>
                <input  [formControl]="form.controls['identifier_customer']" type="text" class="form-control" id="cedula" placeholder="" value="" required="">
                <small
                  *ngIf="form.controls['identifier_customer'].hasError('required') && form.controls['identifier_customer'].touched"
                  class="form-error-msg"> Cédula es requerido </small>
              </div>

              <div class="col-sm-4">
                <label for="email" class="form-label">Email</label>
                <input [formControl]="form.controls['email_customer']" type="text" class="form-control" id="email" placeholder="" value="" required="">
                <small
                  *ngIf="form.controls['email_customer'].hasError('required') && form.controls['email_customer'].touched"
                  class="form-error-msg"> Email es requerido </small>
              </div>



              <div class="col-sm-4">
                <label for="phone" class="form-label">Teléfono</label>
                <!-- <input type="text" class="form-control" id="phone" placeholder="" value="" required=""> -->

                <input style="color:black" style="width: 80%; margin-left: 85px;"  (hasError)="hasError($event)"  (countryChange)="onCountryChange($event)" (intlTelInputObject)="telInputObject($event)" (ng2TelOutput)="getNumber($event)" [ng2TelInputOptions]="{initialCountry: 'pa',separateDialCode:true}" type="text" ng2TelInput />

                <small
                  *ngIf="!errorNumber"
                  class="form-error-msg"> Número de teléfono inválido </small>


              </div>

            </div>

            <div style="text-align: center">
              <button style="margin-top: 30px;" class="btn btn-primary btn-xl " >Crear</button>
            </div>


          </form>
        </div>
      </main>
    </div>
  </header>

</div>

<app-footer></app-footer>
