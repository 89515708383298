<div id="page-top" [class]="class">
    <!-- Mashead header-->
    <header class="masthead coverim" id="inicio">

                <div class="container">
                    <main class="form-signin">
                        <h1 style="text-align: center" class="display-1 lh-1 mb-3">Inicia sesión</h1>
                        <form  [formGroup]="form" (ngSubmit)="signin()" >
                          <div style="margin-top: 10px;" class="form-floating">
                            <input [formControl]="form.controls['username']"  type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                            <label for="floatingInput">Correo</label>
                            <small
                              *ngIf="form.controls['username'].hasError('required') && form.controls['username'].touched"
                              class="form-error-msg"> Correo es requerido </small>
                          </div>
                          <div style="margin-top: 20px;" class="form-floating">
                            <input [formControl]="form.controls['password']" type="password" class="form-control" id="floatingPassword" placeholder="Password">
                            <label for="floatingPassword">Contraseña</label>
                            <small
                              *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched"
                              class="form-error-msg"> Contraseña es requerido </small>
                          </div>

                          <div style="text-align: center">
                            <button class="btn btn-primary btn-xl " >Login</button>
                          </div>

                          <div style="margin-top: 20px;text-align: center" >
                            <a (click)="reset()" style="cursor: pointer;"> Olvidé mi contraseña</a>
                        </div>

                          <div style=" margin-top: 20px;text-align: center">
                            <a (click)="close()" style=" cursor: pointer;"> Volver</a>
                        </div>

                        </form>
                    </main>
        </div>


    </header>

</div>