import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  @Output() manageStatus = new EventEmitter<{status : Number, id : Number}>();


  @Input() tdc : { id:Number ; type: String; number: String; status : String } | any ;

  constructor() { }

  ngOnInit(): void {
  }

  changeStatus(newStatus : Number){
    this.manageStatus.emit({ status : newStatus, id: this.tdc.id });
  }

}
