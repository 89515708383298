import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ImagesService } from 'src/app/services/image/images.service';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import { SuscriptionsService } from './suscriptions.service';

@Component({
  selector: 'app-suscriptions',
  templateUrl: './suscriptions.component.html',
  styleUrls: ['./suscriptions.component.css']
})
export class SuscriptionsComponent implements OnInit {

  //plans = [{name :  "Plan 1", price : 15, description : "Descripción para el plan 1", next_bill_date: "26 de sep de 2022"}, {name :  "Plan 2", price : 25, description : "Descripción para el plan 2", next_bill_date: "29 de oct de 2022"}, {name :  "Plan 3", price : 35, description : "Descripción para el plan 3", next_bill_date: "29 de nov de 2022"}];

  suscriptions = [];

  class : any = "pal1  preferences";

  constructor(private imageService: ImagesService, private authService : AuthService,private suscriptionService : SuscriptionsService ,private webService : WebService, private toast : ToastService, private spinner : SpinnerService) {
    if(localStorage.getItem("pal") !== undefined || localStorage.getItem("pal") !== null){
      this.class = localStorage.getItem("pal") + "  preferences";
    }

    this.suscriptionService.suscriptionUpdated.subscribe( (response : boolean ) =>{
     
      if(response){
        this.loadSubscriptions();
      }
    })
  }

  ngOnInit(): void {
    this.loadSubscriptions();
  }
  
  loadSubscriptions(): void {
    this.spinner.open();
    this.suscriptions = [];
    this.webService.get(this.webService.HOST + "/hasplan/customer/" + this.authService.getCustomer() + "/service/" + this.authService.getService()).subscribe(response =>{
    
      for(let sus of response.result){
        sus.status = sus.status_customerPlan;
        switch (sus.status_customerPlan){
          case 1 : sus.status_customerPlan = "Activa";
            break;
          case 2 : sus.status_customerPlan = "Inactiva";
            break;
          case 3 : sus.status_customerPlan = "Congelada";
            break;
          case 4 : sus.status_customerPlan = "En proceso de cobro";
            break;
          case 5 : sus.status_customerPlan = "No se pudo cobrar";
            break;
        }
  
        this.suscriptions.push(sus);
      }
  
      this.spinner.close();
  
      this.suscriptionService.suscriptions.emit(this.suscriptions);
  
    }, err =>{
      this.spinner.close()
      this.toast.showError(err);
      console.log(err)
    });
  }

  getImagePrincipal(){
    return this.imageService.getImagePalete();
  }

}
