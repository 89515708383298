import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import { AddressPopupComponent } from '../../addresses/address-popup/address-popup.component';
import { PaymentsPopupComponent } from '../../payments/payments-popup/payments-popup.component';
import { ValidatePopupComponent } from './validate-popup/validate-popup.component';

@Component({
  selector: 'app-update-suscription-popup',
  templateUrl: './update-suscription-popup.component.html',
  styleUrls: ['./update-suscription-popup.component.css']
})
export class UpdateSuscriptionPopupComponent implements OnInit {

  form: any ;
  adress: any;
  tdc: any;
  tarjetas : any = [];


  constructor(private dialog: MatDialog, private webService : WebService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<UpdateSuscriptionPopupComponent>, private toast : ToastService, private spinner : SpinnerService,private authService : AuthService ) {
    this.form = new FormGroup({
      id: new FormControl(this.data.suscription.id, Validators.required),
      product: new FormControl(this.data.suscription.plan.name_plan, Validators.required),
      price: new FormControl('$'+this.data.suscription.plan.feeCost_plan, Validators.required),
      frecuency: new FormControl(this.data.suscription.plan.frecuency.name_frecuency),
      next: new FormControl(this.data.suscription.next_bill_date, Validators.required),
      adress: new FormControl('', Validators.required),
      name_tdc: new FormControl('', Validators.required),
      number_tdc: new FormControl('', Validators.required),
      month: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      province : new FormControl('', Validators.required),
      corregimiento : new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {

    this.spinner.open();

    // cargamos la informacion de la direccion asociada a la suscripcion
    this.webService.get(this.webService.HOST + "/adress/" + this.data.suscription.adress_id).subscribe( response =>{

      this.form.controls["adress"].setValue(response.result.adress);
      if(response.result.province != null){
        this.form.controls["province"].setValue(response.result.province.name);
        this.form.controls["corregimiento"].setValue(response.result.corregimiento.name);
      }

      // Buscamos la tarjeta del usuario

      if(this.data.suscription.creditCardToken != null){
        this.webService.get(this.webService.HOST + '/customer/' + this.authService.getCustomer() + '/creditcard').subscribe(response=>{

          this.tarjetas = response.result;

          let t = this.tarjetas[this.tarjetas.findIndex((t:{ tokenCreditCard: any; }) => t.tokenCreditCard == this.data.suscription.creditCardToken.token_creditCardToken)];
          this.form.controls["number_tdc"].setValue(t.creditCard.maskCreditCard);
          this.form.controls["name_tdc"].setValue(t.creditCard.nameHolderCreditCard);

          // para el mes
          let month;
          switch(t.creditCard.yearMonthExpiration.substr(t.creditCard.yearMonthExpiration.length - 2)){
            case "01" : month = "ENERO";
              break;
            case "02" : month = "FEBRERO";
              break;
            case "03" : month = "MARZO";
              break;
            case "04" : month = "ABRIL";
              break;
            case "05" : month = "MAYO";
              break;
            case "06" : month = "JUNIO";
              break;
            case "07" : month = "JULIO";
              break;
            case "08" : month = "AGOSTO";
              break;
            case "09" : month = "SEPTIEMBRE";
              break;
            case "10" : month = "OCTUBRE";
              break;
            case "11" : month = "NOVIEMBRE";
              break;
            default:
              month = "DICIEMBRE";

          }


          this.form.controls["year"].setValue("20" + t.creditCard.expYearCreditCard);
          this.form.controls["month"].setValue(month);
          this.spinner.close();
        });
      }else{
        this.spinner.close();
      }



    }, err =>{
      this.toast.showError(err);
      this.spinner.close();
    });

  }

  update(){

    this.spinner.open();

    if(this.adress != null || this.tdc != null){
      const data: any = {} //this.formEdit.value;
      data.customer = this.authService.getCustomer()
      if(this.adress != null){
        data.adress = {id:this.adress} ;
      }

      if( this.tdc != null){
        data.tdc = {id:this.tdc}
      }

      data.hasPlan = this.form.value.id;

      this.webService.put(data,this.webService.HOST + "/ecommerce/adress/" + data.hasPlan).subscribe(response =>{
        this.toast.showSuccess("Actualizada correctamente")
        this.close(4);
        this.spinner.close();

      }, err =>{
        console.log(err);
        this.spinner.close();
        this.toast.showError(err);
      });
    }else{
      this.toast.showInfo("Debe seleccionar una direccion de envío o una tarjeta distinta a la actual");
      this.spinner.close();
    }

  }

  close(param : any){
    this.dialogRef.close(param);
  }

  addressesPopUp() {

    let dialogRef: MatDialogRef<any> = this.dialog.open(AddressPopupComponent, {
      width: '50%',
      disableClose: true,
      data: {  }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }

        console.log(res);
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        this.adress = res.id;
        this.form.controls["adress"].setValue(res.adress);

        this.form.controls["province"].setValue(res.province.name);
        this.form.controls["corregimiento"].setValue(res.corregimiento.name);

      })
  }

  tdcPopUp() {

    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentsPopupComponent, {
      width: '50%',
      disableClose: true,
      data: { }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        console.log(res.token);
        this.tdc = res.token;
        this.form.controls["name_tdc"].setValue(res.nameHolderCreditCard);
        this.form.controls["number_tdc"].setValue(res.maskCreditCard);
        // para el mes
        let month;
        switch(res.yearMonthExpiration.substr(res.yearMonthExpiration.length - 2)){
          case "01" : month = "ENERO";
            break;
          case "02" : month = "FEBRERO";
            break;
          case "03" : month = "MARZO";
            break;
          case "04" : month = "ABRIL";
            break;
          case "05" : month = "MAYO";
            break;
          case "06" : month = "JUNIO";
            break;
          case "07" : month = "JULIO";
            break;
          case "08" : month = "AGOSTO";
            break;
          case "09" : month = "SEPTIEMBRE";
            break;
          case "10" : month = "OCTUBRE";
            break;
          case "11" : month = "NOVIEMBRE";
            break;
          case "12" : month = "DICIEMBRE";
            break;
        }


        this.form.controls["year"].setValue(res.expYearCreditCard);
        this.form.controls["month"].setValue(month);

      })
  }

  validatePopup(action: string, value : number){

    let dialogRef: MatDialogRef<any> = this.dialog.open(ValidatePopupComponent, {
      width: '50%',
      disableClose: true,
      data: { action: action, type : value }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        switch(value){
          case 1 : this.activateSuscription();
            break;
          case 2 : this.pauseSuscription();
            break;
          default : this.cancelSuscription();
        }

      })

  }

  pauseSuscription(){
    this.spinner.open();
    this.webService.put(null,this.webService.HOST + "/ecommerce/hasplan/" + this.form.value.id + "/pause" ).subscribe(response =>{
      this.spinner.close()
      this.close(1);
    }, (err) =>{
      this.spinner.close()
      this.toast.showError(err);
    })
  }

  activateSuscription(){
    this.spinner.open();
    this.webService.put(null,this.webService.HOST + "/ecommerce/hasplan/" + this.form.value.id + "/activate" ).subscribe(response =>{
      this.spinner.close()
      this.close(0);

    }, (err) =>{
      this.spinner.close()
      this.toast.showError(err);
    })
  }

  cancelSuscription(){
    this.spinner.open();
    this.webService.put(null,this.webService.HOST + "/ecommerce/hasplan/" + this.form.value.id + "/cancel" ).subscribe(response =>{
      this.spinner.close()
      // this.toast.showSuccess("Suscripción cancelada correctamente");
      this.close(2);
    }, (err) =>{
      this.spinner.close()
      this.toast.showError(err)
    })
  }


}
