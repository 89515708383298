import { Component, OnInit } from '@angular/core';
import { Plan } from 'src/app/components/plan/model/plan.model';
import { ImagesService } from 'src/app/services/image/images.service';
import { PlanService } from 'src/app/services/plan/plan.service';
import { ToastService } from 'src/app/services/notification/toast.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { WebService } from 'src/app/services/web/web.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AddressPopupComponent } from '../addresses/address-popup/address-popup.component';
import { PaymentsPopupComponent } from '../payments/payments-popup/payments-popup.component';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import { FacPopupComponent } from './fac-popup/fac-popup.component';
import { LoginService } from '../login/login.service';
import { LoginPopupComponent } from './login-popup/login-popup.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  class : any = "pal1";

  public selectedPlan: Plan;

  planImage : any;

  plan : any;

  provinces: any[]= [] ;
  form: any ;
  corregimientos: any[]= [] ;
  addressID: any;
  tdcID: any;
  showMenu : Boolean = true;
  tdcStatus: any;
  tdcCorerID: any;
  tdc: string;
  hasPlanID: any;
  emailCustomer: any;
  confirmation : Boolean = false;
  isActive = true;

  constructor(public loginService : LoginService,private sanitizer: DomSanitizer,private dialog: MatDialog, private router : Router, private activeRoute : ActivatedRoute ,public auth : AuthService,private imageService : ImagesService, private planService : PlanService,private webService : WebService, private toast : ToastService, private spinner : SpinnerService) {
    if(localStorage.getItem("pal") !== undefined || localStorage.getItem("pal") !== null){
      this.class = localStorage.getItem("pal");
    }
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email]),
      name: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      name_tdc : new FormControl('', Validators.required),
      number_tdc: new FormControl('', Validators.required),
      month: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      ccv: new FormControl('', Validators.required),
      province : new FormControl('', Validators.required),
      corregimiento : new FormControl('', Validators.required)
    });

  }

  ngOnInit(): void {
    console.log(this.planService.getIsActive().toLowerCase());
    if(this.planService.getIsActive().toLowerCase() === 'true'){
      this.spinner.open();
      this.webService.get(this.webService.HOST + "/province").subscribe( provinces =>{

        this.provinces = provinces.result;
        this.webService.get(this.webService.HOST + "/plan/" + this.planService.getPlanSelected()).subscribe(response =>{

          this.plan = response.result;
          this.spinner.close();

        }, err =>{
          this.spinner.close();
          this.toast.showError(err);
        });

      }, err =>{
        this.spinner.close()
        this.toast.showError(err);
      });
    }else{
      this.isActive = false;
    }

  }


  addressesPopUp() {

    let dialogRef: MatDialogRef<any> = this.dialog.open(AddressPopupComponent, {
      width: '100%',
      disableClose: true,
      data: { }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        // TOMAMOS LA INFORMACION DE LA DIRECCION SELECCIONADA
        //console.log(res);
        this.addressID = res.id;
        this.form.controls["name"].setValue(res.name != null ? res.name : '');
        this.form.controls["lastname"].setValue(res.lastname != null ? res.name : '');
        this.form.controls["address"].setValue(res.adress);
        this.form.controls["province"].setValue(res.province.id);
        this.form.controls["corregimiento"].setValue(res.corregimiento.id);

        this.onProvinceChargeChange(res.province.id);

      })
  }
  onProvinceChargeChange(id: any) {

    if(this.addressID != null){
      this.addressID == null;
    }

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == id)].corregimientos;
    this.corregimientos.sort((a,b) =>  (a.name > b.name ? 1 : -1));

  }

  tdcPopUp() {

    let dialogRef: MatDialogRef<any> = this.dialog.open(PaymentsPopupComponent, {
      width: '100%',
      disableClose: true,
      data: { }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        if(!res) {
          // If user press cancel
          return;
        }
        this.tdcID = res.token;
        this.tdcStatus = res.estatus;
        this.tdcCorerID = res.id;
        this.form.controls["name_tdc"].setValue(res.nameHolderCreditCard);
        this.form.controls["number_tdc"].setValue(res.maskCreditCard);
        this.form.controls["month"].setValue(res.yearMonthExpiration.substr(res.yearMonthExpiration.length-2));
        this.form.controls["year"].setValue(res.expYearCreditCard.substr(res.expYearCreditCard.length-2));

      })
  }

  onProvinceChange(){

    this.corregimientos = [];
    this.corregimientos = this.provinces[this.provinces.findIndex(t => t.id == Number(this.form.value.province) )].corregimientos;
    this.corregimientos.sort((a,b) =>  (a.name > b.name ? 1 : -1));

  }

  checkout(){

    let step:any = localStorage.getItem("step");
       
    if(step != 6 && step != 7 && step != -1) {
      return this.toast.showError("Por favor, culmina el pago de tu portal para que se pueda desplegar correctamente...");
    }else{
    const data = this.form.value;

    if(this.auth.isLoggedIn()){

      if(this.form.value.email == null || this.form.value.email == undefined || this.form.value.email == '' ){
        this.form.controls["email"].setValue("example@gmail.com");
      }

      if(!this.form.valid){
        return this.toast.showError("Por favor completa el formulario...");
      }
  
      // ESTE ES EL PROECSO PARA REALIZAR LA SUSCRIPCION
      let tdc : any = {};
      let address : any = {};
      let send : any = {};


      // EN CASO DE HABER SELECCIONADO UNA DE LAS TARJETAS REGISTRADAS

      if(this.tdcID != null ){

        tdc.id = this.tdcID;
        tdc.status = this.tdcStatus;
        tdc.idcorer = this.tdcCorerID;
        tdc.ccv = data.ccv;

      }else{

        tdc.numberCreditCard = data.number_tdc;
        tdc.nameHolderCreditCard = data.name_tdc;
        tdc.expMonthCreditCard = data.month;
        tdc.expYearCreditCard = data.year;
        tdc.ccv = data.ccv;

      }

      this.tdc = 'XXXXXXXXXXX' + data.number_tdc.substring(data.number_tdc.length - 4);

      // EN CASO DE HABER SELECCIONADO UNA DE LAS DIRECCIONES REGISTRADAS
      if(this.addressID != null){
        address.id = this.addressID;
      }else{
        // en caso de no existir la direccion entonces
        address.name = data.name;
        address.lastname = data.lastname;
        address.address = data.address;
        address.province = data.province;
        address.corregimiento = data.corregimiento;
      }
      // armamos el objeto que va a ir al backend

      send = {param: 8, creditcard:tdc, address:address, customer:this.auth.getCustomer(), plan : this.plan.id}

      this.spinner.open();
      this.webService.suscribe(send, this.webService.HOST + "/suscription").subscribe(response=>{

        this.spinner.close();

        if(response.htmlFormData){

          this.hasPlanID = response.id;
          let dialogRef: MatDialogRef<any> = this.dialog.open(FacPopupComponent, {
            width: '60%',
            disableClose: true,
            data: { form: this.sanitizer.bypassSecurityTrustHtml(response.htmlFormData), id: response.id }
          });
          dialogRef.afterClosed()
            .subscribe(res => {
              this.myLoadEvent();
            });

        }else{

        this.hasPlanID = response.result;
        this.emailCustomer = this.form.value.email;
        // this.myLoadEvent();
        if(response.result.status_customerPlan == 1 ){ // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA
          this.confirmation = true;
        }else{
          this.toast.showError("Su pago fue rechazado por el banco");
        }

          // ENVIAMOS EVENTO DE COMPRA A GOOGLE ANALYTICS SOLO SI ESTAMOS EN PRODUCCION
          //  if(environment.production){
          //    let id_plan : any = localStorage.getItem("plan");
          //    this
          //    .googleAnalyticsService
          //    .eventEmitterCheckout("purchase", "ecommerce", "click", "Suscribirse", id_plan,this.option,this.customer);
          //  }

        }
      }, (err) =>{
        console.log(err);
        this.spinner.close()
        // AGREGAMOS ESTO PARA HACER LOGIN EN CASO DE QUE NO ESTE LOGEADO EL CUSTOMER
        this.toast.showError(err);
      });

    }else{

      if(data.email == null || data.email == undefined || data.email == ''){
        return this.toast.showError("Por favor ingrese su email...");
      }

      this.spinner.open();
      this.webService.get(this.webService.HOST + "/auth/" + data.email + "/" + this.plan.service.partner.id).subscribe(response =>{
        this.spinner.close();
        // Mostramos el proceso de login porque existe la cuenta
        this.loginService.removeFromCheckout();
        this.toast.showInfo("Por favor inicia sesion para continuar con la compra...");
        this.showLoginPopup(this.form.value.email);
      }, err =>{
        this.spinner.close();
        this.sendSingUp();
        // mandamos a crear el usuario y realizamos el checkout
      });

    }
  }

  }


  sendSingUp() {

    if(!this.form.valid){
      return this.toast.showError("Por favor completa el formulario para crear tu cuenta y continuar con la compra...");
    }

    this.spinner.open();
    
    var signinData : any = {};
    signinData.name_customer = this.form.value.name;
    signinData.lastName_customer = this.form.value.lastname;
    signinData.identifier_customer = this.form.value.email  ;
    signinData.adress_customer = this.form.value.adress  ;
    signinData.email_customer = this.form.value.email  ;
    signinData.province = this.form.value.province;
    signinData.corregimiento = this.form.value.corregimiento;
    
    // COLOCAMOS ESTATUS E ACTIVO AL CLIENTE
    signinData.status_customer = 1;
    // COMO ES CLIENTE DE LANDING, SU EMAIL SERA EL CODIGO DEL CLIENTE
    signinData.code_customer = this.form.value.email;
      // ESTE ES EL PARTNER QUE ES DUE*O DEL PLAN Y SERVICIO
        signinData.partner = {id: this.plan.service.partner.id};
        signinData.service = this.plan.service.id;
       // REGISTRAMOS AL CUSTOMER
        this.webService.post(signinData, this.webService.HOST + "/onboarding/user").subscribe(result =>{
          
          this.spinner.close();
          // TOMAMOS EL ID DEL CUSTOMER QUE SE ACABA DE GENERAR
          this.loginService.removeFromCheckout();
          this.loginService.setPasswordFromCheckout(result.accessToken);
          this.showLoginPopup(result.customer.email_customer);

        }, err => {
          this.spinner.close();
          console.log(err);
          this.toast.showError(err);
        });


  }

  showLoginPopup(email : any){
    this.showMenu = false;

    this.loginService.isFromCheckout(email);
    let dialogRef: MatDialogRef<any> = this.dialog.open(LoginPopupComponent, {
      width: '100%',
      disableClose: true,
      data: { }
    })
    dialogRef.afterClosed()
      .subscribe(res => {
        this.showMenu = true;
  
        if(res==0) {
          // If user press cancel
          return;
        }
        this.checkout();

      });
  }

  myLoadEvent(){
    this.spinner.open();
    this.webService.get(this.webService.HOST + "/hasplan/" + this.hasPlanID).subscribe(chp =>{
      this.spinner.close();
      if(chp.result.status_customerPlan  != 4){
        if(chp.result.status_customerPlan == 1 ){ // ESTO ES QUE SE COBRO Y TODO BIEN CON LA SUSCRIPCION ESTA ACTIVA

          this.confirmation = true;
          this.emailCustomer = chp.customer.email_customer;

        }else{
          this.toast.showError("Su pago fue rechazado por el banco");
        }
      }
    }, err =>{
      this.spinner.close();
      this.toast.showError(err);
    });
  }

  onlyNumberKey(event : any){
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
