import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-validate-popup',
  templateUrl: './validate-popup.component.html',
  styleUrls: ['./validate-popup.component.css']
})
export class ValidatePopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<ValidatePopupComponent>) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close(this.data.type);
  }

}
