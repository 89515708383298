import { Injectable } from "@angular/core";
import { HttpHeaders } from '@angular/common/http';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import {AuthService} from "../services/auth/auth.service";
//import { JwtAuthService } from "../services/auth/jwt-auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor( private auth : AuthService/*private jwtAuth: JwtAuthService*/) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    var changedReq;
    // BUSCAMOS EL TOKEN DE ACCESO DEL USUARIO

    // SI HAY TOKEN ENTONCES HAY USUARIO
    if (this.auth.isLoggedIn()) {
      let sesion = this.auth.getSesion();
      changedReq = req.clone({
        headers: new HttpHeaders({
          'Authorization':JSON.parse(sesion!).access_token,
          'User': JSON.parse(sesion!).user_id.toString(),
        })
      });

    } else { // EN CASO DE QUE NO HAYA NADA NO SE MODIFICA EL REQUEST

      changedReq = req;

    }
    return next.handle(changedReq);
  }
}
